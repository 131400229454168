// Colors
@use 'sass:math';

$white: #fff;

$text: #74708d;
$text-lighten: #c0bdd0;
$text-darken: #615d7c;
$text-darken-more: #514d6a;

$gray: #d2d9e5;
$gray2: #878787;
$gray-lighten: #eef0f4;
$gray-lighten-more: #f2f4f8;
$gray-lighten-more-more: #f9fafc;
$gray98: #fafafa;
$gray94: #f0f0f0;
$gray85: #d9d9d9;
$gray-text: #8c8c8c;

$gray-darken: #b8beca;
$gray-border: #e4e9f0;

$black: #0e0b20;
$black-lighten: #222034;
$black-lighten-more: #393749;

$blue: #08f;
$blue-darken: #0072d6;
$blue-lighten: #1890ff;
$blue-lighten-ant-default: #1677ff;
$cyan-blue-shade: #4096ff;
$light-blue: #e6f7ff;
$orange: #f2a654;
$seo-header: #1a0dab;
$seo-link: #006621;
$seo-dark: #262626;
$seo-gray: #545454;
$seo-empty: rgba(0, 0, 0, 0.45);
$custom-internal-link: #0496c2;

//meta editor colors
$panache: #f6ffed;
$sulu: #b7eb8f;
$silver2: #bfbfbf;
$laPalma: #389e0d;

$menuBg: #001529;
$menuBg-darken: #000c17;

// Accent colors
$default: #acb7bf;
$primary: #ff4b77;
$secondary: #6a7a84;
$success: #46be8a;
$info: #0887c9;
$warning: #faad14;
$danger: #fb434a;

//context tag colors
$asparagus: #82a969;
$riceFlower: #eaffdd;
$silver: #c0c0c0;
$whiteSmoke: #f5f5f5;

// Font Family
$base__font-family: 'Roboto', sans-serif !important;
$arial: arial, sans-serif !important;

// Font Size
$base__font-size: 13 !default;

// Convert value rem() sass mixin
@function rem($px, $base: $base__font-size) {
  @return #{floor(math.div($px, $base) * 100) * 0.01}rem; // to REMs
  // comment code above and uncomment below for change REMs to PXs
  //@return #{$px}px;
}

$box-shadow-base: 0 0 75px -20px rgba(91, 203, 247, 0.2);

$base-z-index: 1000;

// Transitions
@mixin transition-fast() {
  transition: all 0.05s ease-in-out;
}
@mixin transition-middle() {
  transition: all 0.1s ease-in-out;
}
@mixin transition-slow() {
  transition: all 0.2s ease-in-out;
}
@mixin transition-very-slow() {
  transition: all 0.4s ease-in-out;
}
@mixin transition-color() {
  transition: color 0.2s ease-in-out;
}

@mixin placeholder-text() {
  // base component colors
  color: $gray-darken !important;
  font-weight: 300 !important;
}

// Responsive utils
$xxl-min-width: 1600px;
$xxl-max-width: 1599px;
$xl-min-width: 1200px;
$xl-max-width: 1199px;
$md-min-width: 992px;
$md-max-width: 991px;
$sm-min-width: 768px;
$sm-max-width: 767px;
$xs-min-width: 576px;
$xs-max-width: 575px;
