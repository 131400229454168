@import '../../../../assets/styles/mixins.scss';

.icon {
  margin-right: rem(8);
  color: $gray-darken;
}

.dropdown {
  :global(.ant-dropdown-menu) {
    min-width: 200px;
  }
}
