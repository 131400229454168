@import '../../../assets/styles/mixins.scss';

.dropdownButton {
  padding-right: 15px;
  position: relative;
  cursor: pointer;

  &::after {
    color: $gray;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -2px;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }

  &:hover {
    &::after {
      color: $gray-darken;
    }
  }

  &.disabled {
    opacity: 0.6;
    cursor: default !important;
  }

  :global(.badge) {
    font-size: inherit;
  }
}
