@import '../../../../assets/styles/mixins.scss';

$icon-width: 17.5px; // max width of used icon
$icon-margin: 10px;

//Todo: recheck antD updates CMS-2093
:global(.ant-menu-inline-collapsed-tooltip) {
  display: none;
}

.menu {
  overflow: auto;
  height: 100vh;
  position: sticky !important;
  left: 0;
  top: 0;
  bottom: 0;

  :global(.ant-menu-light),
  :global(.ant-menu-dark) {
    padding-bottom: 48px;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      background: $gray-darken;
      opacity: 0.4;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:after {
      left: auto;
      right: 0;
    }
  }

  :global(.ant-menu-inline-collapsed-tooltip) .icon {
    display: none;
  }

  :global(.ant-layout-sider-zero-width-trigger) {
    top: 10px !important;
  }

  :global(.ant-menu-item),
  :global(.ant-menu-submenu-title) {
    transition: padding 0s !important;
  }
}

.icon {
  margin-right: $icon-margin;
  width: $icon-width;
  display: inline-block;
}

:global(.ant-menu-submenu-popup) {
  .icon {
    margin-right: auto;
    width: auto;
  }
}

.navigation {
  border: none;

  > :global(.ant-menu-item-divider) {
    margin: 0 23px;
  }

  :global(.ant-menu-submenu-inline) > :global(.ant-menu-submenu-arrow) {
    right: 24px;
  }

  &:global(.ant-menu-inline-collapsed) {
    .title {
      display: none;
    }

    :global(.ant-menu-submenu)
      > :global(.ant-menu-submenu-title .ant-menu-submenu-arrow) {
      opacity: 0.2;
    }
  }

  &:global(.ant-menu-light) {
    .icon,
    :global(.ant-menu-submenu-arrow) {
      opacity: 0.4;
    }

    :global(.ant-menu-item),
    :global(.ant-menu-submenu-title) {
      width: 100%;
    }

    :global(.ant-menu-inline.ant-menu-sub) {
      background-color: $whiteSmoke;
    }

    :global(.ant-menu-submenu-selected .ant-menu-inline.ant-menu-sub) {
      background-color: inherit;
    }
  }

  &:global(.ant-menu-dark) {
    color: $gray-darken;

    :global(.ant-menu-item-divider) {
      opacity: 0.2;
    }

    :global(.ant-menu-item),
    :global(.ant-menu-submenu-title) {
      width: 100%;
    }

    :global(.ant-menu-sub) {
      color: $gray-darken;
    }

    :global(.ant-menu-inline.ant-menu-sub) {
      box-shadow: none !important;
      transition: all 0.3s;
    }

    :global(.ant-menu-item) {
      color: $gray-darken;

      > a {
        color: $gray-darken;

        &:hover {
          .icon {
            color: $white !important;
          }
        }
      }
    }

    :global(.ant-menu-item-selected) {
      > a {
        color: $white;

        .icon {
          color: $white !important;
        }
      }
    }

    :global(.ant-menu-submenu:hover),
    :global(.ant-menu-submenu-selected),
    :global(.ant-menu-submenu-open) {
      .icon {
        color: $white !important;
      }
    }

    :global(.ant-menu-submenu-active) {
      .icon {
        color: $blue !important;
      }
    }

    :global(.ant-menu-inline.ant-menu-sub) {
      box-shadow: none !important;
      transition: all 0.3s;
    }

    :global(.ant-menu-item:after) {
      border-right: 3px solid $white !important;
    }
  }
}
