@import url('https://use.typekit.net/wsz0eij.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import 'mixins.scss';

html body {
  overflow-y: auto!important;
}

body {
  font-family: $base__font-family;
  a {
    text-decoration: none !important;
  }
}

label,
.ant-card-head-title,
.ant-page-header-heading-title,
.ant-table-column-title,
.ant-table-thead > tr > th,
.ant-select-item-option-selected,
.ant-select-tree-treenode-selected {
  font-weight: 500 !important;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.ant-tree-select-dropdown .ant-select-tree .ant-select-tree-treenode {
  width: 100%;
}

.ant-tag {
  font-weight: 400;
}

.ant-drawer-content-wrapper,
.preview-pane {
  @media (max-width: $md-max-width) {
    max-width: 80vw !important;
  }
}

.utils__content {
  padding: 1.5rem;
  max-width: 120rem;
  margin: 0 auto;
}

.ant-layout-content {
  background: #f6f5fa !important;
}

.ant-layout-header {
  height: 46px !important;
  line-height: 46px !important;
}

.ant-page-header {
  background-color: $white;
  padding: 16px 24px;

  .ant-descriptions {
    margin-left: 32px;
  }
}

.ant-breadcrumb {
  font-size: 11px !important;
}

.ant-skeleton-content .ant-skeleton-title {
  height: 20px !important;
  margin-top: 8px !important;
}

.ant-descriptions-item-content,
.ant-descriptions-item-label {
  font-size: 13px !important;
  color: #c0bdd0 !important;
}

.card,
.ant-card {
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03) !important;
  border-radius: 6px !important;
  margin: 0 0 3rem 0;
}

.ant-card img {
  max-width: 100%;
}

.dark .ant-card-head {
  color: rgb(255, 255, 255) !important;
  background: #00152a !important;
}

.ant-card-head {
  font-weight: 500 !important;
  font-size: 18px !important;
}

.custom.ant-row {
  margin: 0 0 1rem 0 !important;
}

.ant-input:focus,
.ant-select-focused .ant-select-selector,
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: #93ceff !important;
  border-right-width: 1px !important;
  outline: 0 !important;
  box-shadow: 0 0 3px 3px rgba(37, 150, 255, 0.1) !important;
}

.ant-select-selection__placeholder,
.ant-select-selection-placeholder,
.ant-select-search__field__placeholder,
.ant-input-number-input::placeholder,
.ant-input::placeholder {
  @include placeholder-text();
}

.rdw-editor-toolbar {
  border: none !important;
  padding: 0 !important;
}

.ant-form-item-control {
  line-height: 22px !important;
}

.ant-menu-item,
.ant-menu-submenu-title {
  height: 46px !important;
  line-height: 46px !important;
  padding: 0 24px !important;
}

body .ant-dropdown-menu,
.ant-dropdown-menu-submenu-popup {
  border: none !important;
}

body .language-select .ant-dropdown-menu-item,
body .language-select .ant-dropdown-menu-submenu-title {
  padding: 3px 14px !important;
  font-size: 12px !important;
  line-height: 18px !important;
}

.ant-cascader-menu {
  min-width: 250px !important;
  height: 280px !important;
  margin: 20px 0 !important;
  padding: 0 !important;
  border-right: 1px solid #e8e8e8;
}

.ant-cascader-menu-item {
  padding: 5px 12px !important;
  line-height: 24px !important;
}

.ant-anchor-wrapper {
  background: none !important;
}

.ant-anchor-link {
  padding: 12px 0 12px 26px !important;
  line-height: 2 !important;
}

.ant-form-item-label {
  line-height: 18px !important;
}

.ant-select-select-single,
.ant-select-selection--single {
  height: 38px !important;
  border: 1px solid #f1f1f1 !important;
  border-top-width: 2px !important;
}

.ant-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
  width: auto;
}

.ant-alert {
  border: none !important;
}

.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  height: 20px;
  margin: 0 !important;
}

.ant-table {
  border: none !important;

  .ant-table-content {
    background: white;
  }

  p {
    margin: 0 0 2px 0 !important;
  }
}

.ant-table.virtual-table tbody > tr.ant-table-row:hover > td {
  background: inherit !important;
}

.text-shorten-2 {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  padding: 0 !important;
  overflow: hidden;
}

/* Form styling */

.ant-checkbox-group {
  line-height: 2 !important;
}

.ant-checkbox-group-item {
  display: block !important;
  margin-right: 0 !important;
}

.ant-notification-notice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;

  .ant-notification-notice-with-icon .ant-notification-notice-message {
    margin-bottom: 0;
    margin-right: 25px;
  }
  .ant-notification-notice-close {
    position: unset;
  }
}

.graphiql-container * {
  box-sizing: unset !important;
}

/* Full Calendar Css */
.fc-view-harness,
.fc-view-harness-active,
.fc-view-harness .fc-view-harness-active {
  background-color: white !important;
}

.fc-list-event,
.fc-event,
.fc-event-start,
.fc-event-end,
.fc-event-today,
.fc-event-future {
  cursor: pointer !important;
}

//for the Provider status and Taxonomy status pages
.statusPageOverlayWrapper {
  td.ant-table-cell .ant-badge.ant-badge-status {
    padding-right: 10px;
  }
}
